import React, { useLayoutEffect, useState } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import SEO from "@components/SEO";
import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import { GreenButton } from "@components/GreenButton";

import homeStyles from "@cssmodules/home.module.scss";

export default function Layout({ children }) {
    const { search, pathname } = useLocation();
    const [showContactBtn, setShowContactBtn] = useState(false);

    useLayoutEffect(() => {
        const onScroll = () => {
            if (pathname === "/") {
                if (window.scrollY > window.innerHeight) {
                    setShowContactBtn(true);
                } else {
                    setShowContactBtn(false);
                }
            } else {
                setShowContactBtn(true);
            }
        };

        onScroll();

        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    return (
        <div id="layout-container">
            <SEO />
            <Navbar />
            {children}
            <Footer />
            <GreenButton
                as={Link}
                to={`/hubungi-kami/${search}`}
                className={`${homeStyles.floatingContactUs} ${
                    showContactBtn ? `${homeStyles.show}` : ``
                }`}
            >
                HUBUNGI KAMI DISINI!
                <img
                    src={require("../assets/images/icons/icon-09.svg")}
                    className={homeStyles.waIcon}
                    alt="whatsapp"
                />
            </GreenButton>
        </div>
    );
}
